import '../../App.scss'


function footBar(props){ 
   
  return(
    <div className='section-block-footer'> 
     <div className='hz-bar'/>
        <div className=" header-card"> 
        <p>{props.Descr}</p>
        </div>
        <div className=" header-card"> 
        <p>{props.Location}</p>
        </div>
        <div className=" header-card"> 
        <p> </p>
        </div>
       
    </div>
    )}

export default footBar
