import './Personal.scss';
import Content from './content-block';
import ModalHandler from './modal';
import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import PersonalImage1 from "../../images/Compressed/officer.shadow.jpg";
import PersonalImage2 from "../../images/Compressed/srt2.jpg";
import PersonalImage3 from "../../images/Compressed/mcclep2.jpg";
import { useNavigate, Link} from "react-router-dom";
const Personal = () => {
  let Path = ''
  let navigate = useNavigate(); 
  const routeChange = () =>{ 
    let path = Path
    navigate(path);}
    return(
      <> 
  <div className="container project" >
    <div className="section-block"> 
      <Link to={"./LE"}>
        <Content text="LAW ENFORCEMENT ACCOUNTABILITY" text1="Research and prototype solution to law enforcement accountability and social justice issues " img={PersonalImage1} alt="Law Enforcement Accountability "/>     
      </Link>
      <Link to={"./AS"}>
        <Content text="ACTIVE SHOOTER MONITORING & ALERT" text1="Research and prototype solution to active shooter situational awareness" img={PersonalImage2} alt="Active Shooter Monitoring"/> 
      </Link>
       </div>
  </div>
     
    </>
    )
}
export default Personal;