import './content-block-light.scss'
import Reveal2 from '../functions/Reveal2';


function ContentLight(props){ 

  return(
    <div className="content-block">
       
        <div className='content-container'> 
        
            <img className="content-thumb" style={{backgroundImage: `url(${props.img})`}} />
            <div className='content-block-text-frame'> 
            <Reveal2>  
                <div className="content-block-text-light"> 
                    <h2 >{props.text}</h2>
                    <h3> {props.text1}</h3>
                    <p> {props.text2}</p>
                </div>
           
            </Reveal2>
            </div>
            </div>
            
    </div>
    )}

export default ContentLight
