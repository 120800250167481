import React, { Component, useState, useEffect} from "react";
import '../../../App.scss';
import './as.scss' 
import Section from "../../Layout/Section";
import Content from '../content-block';
import HeaderBar from "../headerBar";
import FootBar from "../footerbar";
import ContentBar from '../content-bar';
import Gallery1  from '../../functions/gallery1'

function AS() {
    useEffect(() => {
        window.scrollTo(0,0);
     }, [])
    return   <>
    
    <HeaderBar Descr="PERSONAL WORK" Location="JUST FOR MYSELF" time ="2018 - 2019"/>
        <div className="container title-card AS-title">
            <div className="section-block header-section">
             <div className="text-block row title-block ">
                    <div className="sffw-title-frame"> 
                        <h1> ACTIVE SHOOTER DETECTION & ENVIRONMENT TRACKING PROTOTYPE</h1>
                    </div>
                </div>
                <div className="text-block row title-block ">
                </div>
             </div>
        </div>
          <div className="container">
            <div className="section-block  text-section">
            <div className="text-block">
              <h2>ABOUT</h2>
              <h3> "Since 2015, over 19,000 people have been shot and wounded or killed in a mass shooting. In 2022 alone, over 600 people were killed, with over 2,700 wounded." - <a href="https://everytownresearch.org/mass-shootings-in-america/" target="_blank"> Everytown Research & Policy</a></h3>
              <h3> I believe there can be better responses to mass shootings in some environments. By leveraging existing technologies, 
                law enforcement should be able to close their knowledge gap, decrease response time, and maintain situational awareness during these events. </h3>
            </div>
              <div className="text-block"> 
                
            
                <p>
                There are obvious resolutions to gun violence in the U.S. Yet I haven’t been seeing much progress on that. What I have noticed is a criticism on law enforcement response to some of these incidences. Some of it deserved. Hearing these criticisms, my take was the responding officers were constrained by a lack of information. 
                </p>
                <p>
                In my previous employment, I have documented training scenarios for law enforcement. Sometimes that had me accompany special response teams (or SWAT teams) as they conducted active shooter drills. These scenarios were conducted in a former school, where the team would navigate through as if it were a real-world scenario. A selected individual would play the role of the shooter, and the team had to proceed through the building, clearing each room, searching for the active shooter stand-in. 
                </p>
                <p>
                This process was slow. And understandably so. The team moved in single file (to minimize casualties if the shooter was ever engaged) from room to room narrowing down potential locations the shooter could be located. Without obvious information – such as gunshots – there was no way for the team to know exactly where the shooter was. 
                </p>
                <p>
                In a real-life scenario, what potential consequences could arise from this? The speed at which specialized teams can effectively resolve a situation is, at best, not particularly swift. This limitation is inherent. Law enforcement's response to an active shooter event is hindered by a lack of comprehensive information. Upon arrival, their knowledge is often limited to the fact that an incident is underway (at times this isn't even true), and they may approximate the general vicinity of the shooter based on the sound of gunfire.
                </p>
                <p>
                In the event of officers arriving in a staggered manner, how can they efficiently approach the scene, especially the first few to arrive? Are they adequately prepared to clear the area? Do they receive specialized training similar to the special response team I had witnessed? How well do they manage their fear and anxiety in such situations?
                </p>
                <p>
                I am observing only one trend when it comes to mass shooting events: an alarming increase. Unless we take significant steps to reconsider our relationship with firearms, these incidents will persist, resulting in increasingly devastating tragedies for which finding a genuinely effective response will become nearly impossible.                </p>
                <p className="p-bold"> 
                This project was to explore a possible technical solution to this critical information gap.
                </p>
                </div>
              </div>
            </div>
            <div className="container">
                <div className="section-block text-section">
                <div className="text-block"> 
                    <h2>GOAL</h2>
                </div>
                    <div className="text-block">
                        <p>I wanted to use image detection to identify a shooter, triggering an alarm w/o human intervention, and monitor the location of that shooter within a given space – like a building such as a school or an office. </p>                
                    </div>
                </div>
          </div>
          <div className="container">
            <div className="section-block text-section">
            <div className="text-block">
            <div className="text-block"> 
                <h2>IMAGE DETECTION</h2>
            </div>
            </div>
                <div className="text-block">
                    <p>
                    I began with training the image recognition. I used a haar cascade which is commonly found in technology like facial recognition. A Haar cascade is a machine learning object detection program that can be used to identify certain objects in images and video.
                    </p>
                    <p>
                    To train the cascade, I needed a repository of both positive and negative images. Positive images containing the object desired to be detected, and negative being just about anything else. 
                    </p>                          
                </div>
                </div>
          </div>
        <div className="container">
            <div className="section-block text-section">
            <div className="text-block"> 
                <h2>SCRAPING</h2>
            </div>
                <div className="text-block">
                    <p>
                    To get these image collections, I built a scraper in python to pull images from Flickr.com related to a given tag. The tags included “firearm”, “pistol”, “rifle”, “shooter”, and others. The scraper was run once for each tag. It compressed the files into a 200px square and applied a grey filter to streamline the training. 
                    </p>
                    <p>
                    For the negative image repository, I ran the same script using tags like “people”, “person”, “child”, “children”, “crowd”, etc. to collect an image set of what I assumed would be a typical scenario in the scene I wanted to monitor – work and school environments. 
                    </p>
                    <p>
                    These methods weren’t perfect, but I was more interested in seeing if I could even get this system working and assumed methods could be improved for accuracy later on. 
                    </p>                          
                </div>
            </div>
        </div>
        <div className="container">
            <div className="section-block text-section">
            <div className="text-block"> 
                <h2>CASCADE TRAINING</h2>
            </div>
                <div className="text-block">
                    <p>
                    To train the cascade model, I downloaded an application called <a href="https://amin-ahmadi.com/cascade-trainer-gui/" target="_blank" title="Cascade Trainer GUI site"> Cascade Trainer GUI</a> which made things very easy. The app allowed me to point to both the positive and negative image repositories and adjust the number of passes the training would run through. Each pass, presumably, increasing the accuracy of the image detection. The process produces a ‘cascade.xml’ file, which contains the classifier to be referenced for image detection.  
                    </p>                                            
                </div>
            </div>
        </div>
        <div className="container">
            <div className="section-block general"> 
                <div className="prjct-image as-image  haar-image"></div>
                <div className="prjct-image as-image image-opencv"></div>
                <div className="prjct-image as-image  image-positive"></div>               
            </div>
        </div>
        <div className="container">
            <div className="section-block text-section">
            <div className="text-block"> 
                <h2>3D MODEL</h2>
            </div>
                <div className="text-block">
                    <p>
                    For the environment model, I used my work’s office space as the test subject. Measuring hallways and rooms that I had access to, then estimating the measurements of those I couldn’t. I recreated the space in <a href="https://www.blender.org/" title="Blender.org" target="_blank"> Blender  </a> – a free and open source 3d modeling software – building it to a moderate level of detail.                     
                    </p>   
                    <p>
                    After construction of the model, I imported it into Unity – a video game engine - to bring functionality and navigation to the space. I chose <a href="https://unity.com/" title="unity.com" target="_blank">Unity</a> since I had some experience exploring c# which is the primary programming language for the tool. In each separate room in the model, I added a transparent box object which had a single function of changing materials – in this case a vibrant red – at a certain trigger.     
                    </p>                                         
                </div>
            </div>
        </div>
        <div className="container">
            <div className="section-block general"> 
                <div className="prjct-image as-image image-office1"></div>
                <div className="prjct-image  as-image image-office2"></div>
                <div className="prjct-image  as-image image-office3"></div>
            </div>
        </div>
        <div className="container">
            <div className="section-block text-section">
            <div className="text-block"> 
                <h2>CAMERAS</h2>
            </div>
                <div className="text-block">
                    <p>
                    I had limited options available for my setup. I used both my laptop's built-in camera and a USB Webcam. To mimic a network of cameras, I connected the USB Webcam to a Raspberry Pi and created a server instance on my laptop. This allowed me to establish a connection to the Pi within the Unity "game."
                    </p>   
                    <p>
                    In this prototype, the setup was designed to centralize the image detection process. Rather than assessing each camera feed individually on the Pi or laptop camera, the server handled this task.
                    </p>
                    <p>
                    Each camera was virtually linked to a specific room within the 3D model. When the image detection on a camera's feed was triggered, a script would execute to change the material of the corresponding room's transparent box. If no image was detected, the material would revert to its transparent state.
                    </p>                                         
                </div>
            </div>
        </div>
        <div className="container">
            <div className="section-block general"> 
                <div className="prjct-image as-image image-picam"></div>
                <div className="prjct-image as-image image-fakegun"></div>
                <div className="prjct-image as-image image-inaction"></div>
            </div>
        </div>
        <div className="container">
            <div className="section-block text-section">
            <div className="text-block"> 
                <h2>OUTCOMES</h2>
            </div>
                <div className="text-block">
                    <p>
                    In summary, the system had some success. It effectively linked camera streams to corresponding rooms in the model, activating a room with alarming red when a firearm was detected on a specific camera's feed. However, the system's cascade wasn't flawless, resulting in several false positives during testing. Some trained models performed better than others, but it was clear that more diverse and representative training data, along with additional refinement time, was needed to achieve the desired accuracy.
                    </p>   
                    <p>
                    Another challenge lay in the creation of the environment model. Building the model in software like Blender was a time-consuming process and lacked scalability. Fortunately, this issue was being addressed with the growing accessibility of photogrammetry technology, even available on devices like iPhones.
                    </p>
                    <p>
                    As a proof of concept, the system demonstrated promise. With further refinement and proper implementation, it could effectively track an active shooter as they moved through an environment. In the context of law enforcement response, the modeled environment could be hosted on a server, and officers could access it remotely, perhaps on a tablet. This would provide them with real-time situational awareness regarding the shooter's location, potentially reducing the time required for familiarizing themselves with the environment and systematically clearing each room.
                    </p>
                    <div className="video-responsive">
                    <iframe  
                    src="https://www.youtube.com/embed/sZ6cMUcwG-k?si=IHRvivl4iWto5Vjb" 
                    title="Demo video of detection system" 
                    frameborder="0" 
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                    allowfullscreen></iframe>                     
                    </div>              
                </div>
            </div>
        </div>
        <FootBar Descr="Get in Touch -->" Location="mike@mcameron.io"  />
            </>
              }
              export default AS;