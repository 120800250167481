import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';


var listOfImages =[];


 class Gallery1 extends  React.Component{
   
    importAll(r) {
        return r.keys().map(r);
    }

    componentWillMount() {
        listOfImages = this.importAll(require.context('../../images/mcclep', false, /\.(png|jpe?g|svg)$/));

    }
    render(){
         return(
        <div className='container'>
           <div className='section-block gallery-block'>
              {
                    listOfImages.map(
                    (image, index) => 
                    <div className='gallery-frame'> 
                     <img className="gallery-cell" key={index} src={image} alt="info"></img>
                     </div>
                    )
              }
              
          </div>  
          </div>
        )
    }
    }
  export default Gallery1;