import './content-bar.scss'
import Button from 'react-bootstrap/Button';


function ContentBar(props){ 

  return(
    <div className="content-bar ">  
        <div className='content-bar-container'> 
            <div className="content-img" style={{backgroundImage: `url(${props.img})`}} />
        
            <div className="content-text"> 
                <div className="content-text-frame">
                    <h2 >{props.text}</h2>
                    <p> {props.text1}</p>
                </div>
            </div>
        </div>
    </div>
    )}

export default ContentBar
