import React, { Component, useState, useEffect} from "react";
import '../../../App.scss';
import './SFFW.scss' 
import HeaderBar from "../headerBar";
import FootBar from "../footerbar";
import Gallery  from '../../functions/gallaery'

function SFFW() {
  useEffect(() => {
    window.scrollTo(0,0);
 }, [])
    return   <>
    
        <HeaderBar Descr="DESIGN STRATEGIST & GRAPHIC DESIGNER" Location="@ HQ USMC - SFFW " time ="2015 - 2018"/>
        <div className="container title-card sffw-title">
            <div className="section-block header-section">
                <div className="logo-frame sffw-logo">
                </div>
                <div className="text-block row title-block ">
                    <div className="sffw-title-frame"> 
                        <h1> SAN FRANCISCO FLEET WEEK  </h1>
                    </div>
                </div>
            </div>
          </div>
          <div className="container">
            <div className="section-block  text-section">
            <div className="text-block"> 
              <h2>ABOUT</h2>
            </div>
              <div className="text-block"> 
                <p> 
                  San Francisco Fleet Week (SFFW) began in 1981 when then Mayor Diane Feinstein led the nation in celebrating America’s sea services.
                </p>
                <p> Taking place every October on the Marina Green over Italian Heritage Weekend, SFFW’s air show, parade of ships and many community events have become a significant and integral part of the city’s local culture and economy.
                </p>
                <p>  As the largest and most significant event of its kind in the nation, SFFW features a unique training and education program that brings together civilian and military forces to develop and share best practices in humanitarian assistance and emergency disaster response.
                </p>
                <p> Consistently attracting millions of visitors, creating important and lasting relationships for its partners, and generating over 10M in annual revenue for the city, SFFW is now recognized by the United States Department of Defense as the model for fleet weeks across the country.
                </p>
                <p> San Francisco Fleet Week is an annual public event that honors the contributions of the men and women of the United States Armed Forces while advancing cooperation and knowledge among civilian and military based humanitarian assistance and disaster response personnel.
                <a href="https://fleetweeksf.org/" target="_blank" title="SFFW Homepage"> - San Francisco Fleet Week </a>
                </p>
                </div>
              </div>
            </div>
            <div className="container">
          <div className="section-block text-section">
          <div className="text-block"> 
            <h2>ROLE</h2>
          </div>
            <div className="text-block">
                  <p><b> Documentation & Social Outreach: </b></p>
                  <p>
                    Collaborating closely with military public affairs officers and representatives from the Fleet Week Association, I played a pivotal role in creating visual content to complement our social media posts and promotional efforts. In the lead-up to the event, my responsibilities included crafting impactful graphic content for promotional materials and expressing our gratitude to SFFW sponsors. Alongside these public affairs officers, I ventured across the city to document the events, ensuring rapid turnaround for publishing on various online platforms.
                  </p>
                  <p><b> Print Layout & Design: </b></p>
                  <p>
                    San Francisco Fleet Week encompasses a range of significant events, including the Senior Leaders Seminar, Veterans Seminar, and Peer-to-Peer Medical Exchange. These seminars serve as vital platforms for fostering collaboration between the Department of Defense (DoD) and Civil authorities. Esteemed keynote speakers and panelists gather to engage in discussions on critical topics like humanitarian assistance and disaster response, veteran employment opportunities, and the ongoing global refugee crisis.
                  </p>
                  <p>
                    My role entailed the design of program booklets, including cover imagery, layout, and advertisements, as well as the creation of eye-catching posters for the day's events.
                  </p>
                  <p><b>Video Production </b>  </p>
                  <p>
                    As part of our preparations for events like the Senior Leaders Seminar, there's often a need for video introductions to set the stage for certain topics or speakers. My role involved sourcing relevant footage from databases like the Defense Video Imagery Distribution System and skillfully editing these clips into concise summaries before the start of SFFW.
                  </p>
                  <p>
                    Throughout the week, we would conduct a rehearsal of concept drill, where participants from both the Department of Defense (DoD) and the City simulate emergency and disaster response scenarios. This demands a swift turnaround, with a video of the demonstration produced in less than 24 hours. This summary video is then presented during the Senior Leaders Seminar and shared online with those unable to attend.
                  </p>
                  <p>
                    These scenarios can range from Navy landing craft delivering utility trucks and equipment to the San Francisco Department of Emergency Management conducting simulated distribution of relief supplies. These demonstrations effectively showcase the current capabilities and enhance collaboration between the DoD and City authorities."
                  </p>
            </div>
          </div>
        </div>
        <div className="container">
           <Gallery />     
        </div>
        <FootBar Descr="Get in Touch -->" Location="mike@mcameron.io"  />
            </>
              }
              export default SFFW;