import React, { Component, useState, useEffect} from "react";
import '../../../App.scss';
import './mcclep.scss' 
import HeaderBar from "../headerBar";
import FootBar from "../footerbar";
import Gallery1  from '../../functions/gallery1'

function MCCLEP() {
  useEffect(() => {
    window.scrollTo(0,0);
 }, [])
    return   <>
    
        <HeaderBar Descr="GRAPHIC ANALYST / DEVELOPER" Location="@HSSI" time ="2013 - 2019"/>
        <div className="container title-card mcclep-title">
            <div className="section-block header-section">
             <div className="text-block row title-block ">
                    <div className="sffw-title-frame"> 
                        <h1>HOMELAND SECURITY SOLUTIONS INC. & MARINE CORPS CIVILIAN LAW ENFORCEMENT PROGRAM </h1>
                    </div>
                </div>
                <div className="text-block row title-block ">
                </div>
             </div>
        </div>
          <div className="container">
            <div className="section-block  text-section">
              <h2>ABOUT</h2>
              <div className="text-block"> 
                <p> 
                    "The Marine Corps initiated a Civilian Police force in 2005 at Marine Corps Logistics Base, Albany, Ga., Marine Corps Support Facility, Blount Island, Jacksonville, Fl., and MCLB Barstow, Ca. In June 2007, the Marine Corps adopted a plan to begin hiring Civilian Police Officers at all installations in order to reduce the operational stress of Marine Corps Military Police and enhance security and police services across the Marine Corps. As we move further into FY16, there are over 850 Civilian Police Officers working within MCCLEP and hiring will continue for the foreseeable future at all Marine Corps Installations.
                </p>
                <p> 
                    The goal of MCCLEP is to supplement our professional law enforcement agency with individuals that are well trained, disciplined, and responsive to the Marine Corps Community and share in the reputation of the force it protects." - HQMC.mil/Plans Policies and Operations (PP& O)
                </p>
                <p> <a href="http://www.hqmc.marines.mil/ppo/Units/Security-Division-PS/Law-Enforcement-Corrections-PSL/Marine-Corps-Civilian-Law-Enforcement-Program/" target="_blank" title="USMC PP&O Homepage"> - HQMC.mil/Plans Policies and Operations (PP&O) </a>
                </p>

                <p>  
                Homeland Security Solutions, Inc. is "...a large training solutions, technology, and professional services company supporting law enforcement and security needs worldwide". One of those services is supplying instructors and personnel in support of the MCCLEP program. While being employed by HSSI my involvement with this program has varied form documentation to promotion.
                </p>
                <p> 
                <a href="https://www.hssi.com/" target="_blank" title="HSSI Homepage"> - Homeland Security Solutions Inc. </a>
                </p> 
                </div>
              </div>
            </div>
            <div className="container">
          <div className="section-block text-section">
            <h2>ROLE</h2>
            <div className="text-block">
                <p><b> Photography & Documentation: </b></p>
                <p>
                  Travel to US Marine Corps sites: the Police Academy East, situated at Camp Lejeune, North Carolina, and the Police Academy West, located in Miramar, California. My objective would be to effectively document the training activities of Police Cadets and active duty officers through the use of photography and videography.
                </p>
                <p><b>Media Production: </b></p>
                <p>
                    Responsible for overseeing the entire production process, which included arranging travel to the destination, capturing audiovisual content, editing and producing it, and ultimately delivering the media for use as commercial and promotional material. This material was intended to support the Marine Corps Civilian Police Program at specific job fair events, public displays, social media, and online video distribution.
                </p>
                <p><b>Instructional Design & Distance Learning</b></p>
                <p>
                  Responsible for the generation and publication of SCORM compliant e-learning courses for Headquarters USMC. Using Trivantis' Lectora e-learning. Was administrator for Moodle based learning management system. 
                </p>
                <p><b>Web Design </b></p>
                <p> Designed and developed the  Marine Corps Civilian Law Enforcement Program website. Stylistically integrated into the <a href="http://www.marines.mil/" title="USMC Homepage" target="_blank">  US Marine Corps </a> brand for cohesion. </p>
                <p><b>Web Development (USMC LEOSA) </b></p>
                <p>
                The Law Enforcement Officers Safety Act (LEOSA), enacted in 2004, allows qualified law enforcement officers and retired law enforcement officers to carry concealed firearms throughout the United States and its territories, bypassing state and local laws, with some restrictions. This applies to civilian and military police officers, including those from the Marine Corps and Navy, who used the usmcleosa.com secure email service for applications.
                </p>
                <p>
                Working directly with HQMC representatives, I developed an initial intake system via site - built with basic HTML5 + PHP - for USMC LEOSA applicants. the site was designed to guide applicants in understanding the eligibility criteria, promoting self-assessment, and filtering out unqualified applicants. It features an informative portal with slides that clarify the law's limitations, application requirements, and available services.
                </p>
                <p>since my departure, HQMC has brought their LEOSA intake process into their own site @<a href="https://www.lawenforcement.marines.mil/LEOSA/" target="_blank" title="USMC Law Enforcement LEOSA page"> USMC Law Enforcement</a></p>
                
            </div>
          </div>
        </div>
        <div className="container">
           <Gallery1 />     
        </div>
        <FootBar Descr="Get in Touch -->" Location="mike@mcameron.io"  />
            </>
              }
              export default MCCLEP;