import './Projects.scss';
import '../../App.scss';
import Content from './content-block';
import FadeIn from '../functions/Underline';
import { useState } from 'react';
import { useNavigate, Link} from "react-router-dom";

function Projects(props){ 
  let Path = ''
  let navigate = useNavigate(); 
  const routeChange = () =>{ 
    let path = Path
    navigate(path);}
    return(
      <> 
    <div className="container project">
    
    <div className='project-text-frame'> 
      <div className="prjct-text-holder">
        <div className="text-card sm-left-side">  
        <Link to={`/${props.Path}`}>
         <h1 className="hover-underline-animation"> {props.text1} </h1>
         </Link>
        </div>
        <div className="text-card prjct-descrption">  
            <p > {props.text2}</p>
        </div>
      </div>
    </div>
    <div className='project-image-frame'> 
      <Link to={`/${props.Path}`}  className="prjct-image-holder" >
        <div className="prjct-image"  onClick={routeChange} style={{backgroundImage: `url(${props.prjctImage})`}}  />
      </Link>
    </div>
         
  </div>
    </>)
    }
  
  export default Projects
  