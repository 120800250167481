import React, { Component, useState, useEffect} from "react";
import '../../../App.scss';
import './FNS.scss';
import HeaderBar from "../headerBar";
import Footer from "../footerbar";
import Content from "../../Layout/content-block-light";
import map from "../../../images/FNS/map.jpg";
import workflow from "../../../images/FNS/workflow.jpg";
import timeline  from "../../../images/FNS/timeline.jpg";
function FNS() {
  useEffect(() => {
    window.scrollTo(0,0);
 }, [])
  return   <>
      <HeaderBar Descr="DESIGN LEAD" Location="@ STEAMPUNK" time ="2021 - 2022"/>
      <div className="container title-card fns-title-container">
        <div className="section-block text-section">
          <div className="text-block row title-block">
                <h1> USDA: FOOD & NUTRITION SERVICES - EMPLOYMENT & TRAINING DATA SYSTEM </h1>
          </div>
          <div className="text-card m-right-side">
                <h3>
                Our team was brought on to bridge the gap between an existing FNS E&T data assessment and the actual implementation of a system that caters to the program's specific needs. Our responsibilities encompassed reviewing past work, gathering requirements, exploring potential IT solutions, and finally, devising a clear plan for the procurement of the E&T data system.
                </h3>
          </div>
          </div>
        </div>
        <div className="container">
          <div className="section-block">
            <h2>ROLE</h2>
            <div className="text-block">
            <h3>Role Summary </h3>
              <p className="p-bold"> 
                <ul>
                <li>Conduct discovery into FNS' current reporting process</li>
                <li>Engage FNS analysts across nation to understand their region's specific dynamics </li>
                <li>Define business and user requirements</li>
                <li>Co-create desired feature with client team</li>
                <li>Generate system design and process flows</li>
                <li>Lead human-centered assessment of potential future state solutions</li>
                </ul>
              </p>
              <p>
              The team was small. There was a PM, two solution architects, and myself as the only designer. On small focused projects, being the only designer is fine with me. It keeps things agile. My role was to engage with the Office of Employment and Training stakeholders – at their DC headquarter as well as their regional representatives across the U.S. – and gain an understanding of their challenges and vision for the future system. 
               </p>
               <p>
               From there, I needed to merge known data requirements based on the 2022 E&T Data Reporting Assessment with the preferred state derived from stakeholder interviews. I generated several mappings to represented the systems future state – a system workflow, a low-fi UI site map, and an E&T reporting lifecycle. Since this was an advisory project, the artifacts were used in confirming good-fit technology and packaged with the final technical exploration report. 
               </p>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="section-block">
            <h2>ABOUT</h2>
            <div className="text-block">
              <p>
              The SNAP Employment and Training (E&T) program, mandated by the Food Security Act of 1985, serves the critical purpose of equipping participants with the skills and training needed to secure stable employment and enhance their economic self-sufficiency. The USDA is fully committed to fostering collaboration with states and stakeholders, emphasizing the development of data-informed, efficient E&T programs, and highlighting their track record in improving employment and income outcomes for program beneficiaries. Under the Food and Nutrition Act of 2008, federal grants provide essential support for state-administered E&T initiatives, comprising allocations for program operation and grants dedicated to maintaining SNAP eligibility for at-risk, able-bodied adults without dependents. Additionally, the USDA extends reimbursement at 50 percent for non-federal expenditures related to participant expenses such as dependent care and transportation. States exercise significant flexibility in customizing and managing their E&T programs to align with local workforce needs.
              </p>
              <p>
              The Agriculture Improvement Act of 2018 brought substantial changes to the E&T program, emphasizing its strengthening, state accountability, and the enhancement of employment prospects for SNAP recipients. These changes were further operationalized through a final rule issued by FNS in January 2021, effective as of March 2021, necessitating states to adapt their E&T program operations accordingly. Playing a pivotal role in this endeavor, the Office of Employment and Training (OET) within FNS, along with its regional offices, provides invaluable technical assistance and policy guidance to states and their partners. This support encompasses a range of mediums, including national technical projects, webinars, written tools, and, prior to the COVID-19 pandemic, in-person training sessions. To ensure transparency and accountability in program operations, FNS systematically collects information on state E&T programs through multiple mechanisms, including State SNAP E&T Plans, the FNS-583, and annual outcome reporting measures. States are required to make their SNAP E&T plans accessible to the public, promoting clarity regarding program operations and plans for the upcoming year.
              </p>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="section-block">
            <h2>CHALLENGE</h2>
            <div className="text-block">
              <p>
              At project initiation, our team received a previously conducted E&T data assessment. This aimed to identify gaps between SNAP E&T data needs and the existing technical systems and processes at federal, state, and provider levels. We needed to utilize the insights gained from this assessment to guide the project. Early on, the team conducted several meetings with Office of Employment and Training staff and stakeholders to discuss key findings of the assessment. The purpose of these discussions was to understand…
              <ul>
                <li>
                The present and anticipated data usage needs and goals of SNAP E&T.  
                </li>
                <li>
                The current technical systems and businesses processes the Federal government uses to collect, validate, analyze, and report E&T data.  
                </li>
                <li>
                The current technical systems and businesses processes State agencies use to collect, analyze, validate, and report data.  
                </li>
              </ul>              
             </p>
             <p>
             After these preliminary discussions, our goal was to explore various IT solutions that could meet the criteria described in the data assessment. Focusing on interoperability requirements, data standards, system architectures, cybersecurity, privacy protection, data governance, and data sharing agreements. With several options identified, we needed to bring this technical assessment to the client team to discuss pros/cons and define a path forward for development. 
             </p>
            </div>
          </div>
        </div>
        
        <div className="container project project-page" >
              <div className="section-block general"> 
                <Content text="Report Process" text1= " Personnel at each level of the E&T reporting process experienced unique demands throughout the year. For each reporting modality, we needed to understand where the bottlenecks occurred, for whom they were occurring, and what outside factors could be contributing. I built this map to assist in collaborative discussions with E&T stakeholders to identify these. It was then used to highlight areas if improvement in the future state." img={timeline} alt="Affinity Mapping Results"/>
                <Content text="User Workflow" text1= "By interviewing the stakeholders, I was able to accurately capture their current – often manual – processes and mirror them into concise digital ones. By understanding where they fit in the annual reporting lifecycle, the system would guide them towards appropriate use cases at the right times.  " img={workflow} alt="Affinity Mapping Results"/>
                <Content text="App Structure" text1= "From the beginning, the team had a few platforms in mind to recommend. After we sufficiently understood the requirements, we wanted to understand how the users navigated the reported data, and if a given technology could easily provide that experience. I generated  a notional site map to reference during co-creations with the client team. Our goal was to compartmentalize the sizable data E&T users needed to assess, and present in a sensible and digestible way. This artifact was an effective tool in our final determination. " img={map} alt="Affinity Mapping Results"/>
              </div>
        </div>
        <div className="container">
          <div className="section-block">
            <h2>OUTCOME</h2>
            <div className="text-block">
              <p>
              With our final workplan and roadmap submitted, the client team reviewed our recommendations and intended solution and approved. They were happy with our understanding of their needs and wanted to see us develop the new system. The chosen technology was Salesforce, and my contribution to how it should be realized was summarized into Design Pillars.
              </p>
              <p>
                <b>
                Let Technology do the Heavy Lifting 
                </b>
              </p>
              <p>
              Both E&T and state staff do not have the bandwidth to take on any additional responsibilities - preferably less. The next process for E&T report intake and assessment must achieve the requirements without any new burdens. Where possible, the technology should be leveraged to perform base validations, reminders, corrections, and identification of concerning trends. This is all in the art of the possible. For regional and national users, so much of their time goes into preparing state submitted data for assessment. The tool must circumvent any current manual processes.
              </p>
              <p>
                <b>
                Tell a Story 
                </b>
              </p>
              <p>
              E&T stakeholders must be able to tell a story at a national scope and down to the specific states. The data coming in must be accurate, clearly understandable, and presented in a way that reviewers can draw out a narrative at these national & state perspectives. State provided data will be used to illustrate the story of E&T this can be achieved with certain comparisons - state plan vs actual or past performance - as well as tapping into expanded sources - such as Department of Labor - to know where E&T efforts are and where they should be going.              </p>
              <p>
                <b>
                Keep Things Simple 
                </b>
              </p>
              <p>
              State capacity is limited. any new tool they are expected to use needs to provide an accessible and, where possible, guided experience. Staff in the states can come and go, and the loss of experienced personnel cannot derail the state reporting efforts. The tool must have clear directions, streamline submission processes, and leverage previously submitted data where possible.
              </p>
              <p>
              For regional and national users, time is spent running analysis on state provided data. This is often done 'by hand'. With a new system, these users need to have analytics prepackaged and ready for review whenever available. The tool should be built to provide identified core analytics readily accessible for these user roles.
              </p>
              <p>
                <b>
                Bridge Gaps 
                </b>
              </p>
              <p>
              The next system cannot guarantee corrections will never be needed. In this event, effective communication is required. The system should avoid silo-ing efforts between user groups. For example, state users prepare and submit data into a virtual black box, then wait for a signal for when an adjustment is required. On the other side, regional and or national users review the submissions, then prepare an external doc. or message - copy/pasting reference material to clarify the ask - this is an unnecessary separation. The process requires collaboration and discussion and the tool must be fit to facilitate this.
              </p>
            </div>
          </div>
        </div>
        <Footer Descr="Get in Touch -->" Location="mike@mcamerion.io" />

    </> 
    
  }
  export default FNS;