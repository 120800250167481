import { useRef } from "react";
import { useInView } from "framer-motion";


export default function Reveal2({ children }) {
    const ref = useRef(null);
    const isInView = useInView(ref, { margin: "-200px 0px -100px 0px" });

    return (
        <section ref={ref} >
            <span
                style={{
                    transform: isInView ? "none" : "translateX(-200px)",
                    opacity: isInView ? 1 : 0,
                    transition: "transform  1.5s ease, opacity  1.5s ease-out"
                }}
            >
                {children}
            </span>
        </section>
    );
}