import '../../App.scss'
import Button from 'react-bootstrap/Button';


function HeaderBar(props){ 

  return(
    <div className='section-block-header '> 
        <div className=" header-card"> 
        <p>{props.Descr}</p>
        </div>
        <div className=" header-card"> 
        <p>{props.Location}</p>
        </div>
        <div className=" header-card"> 
        <p>{props.time}</p>
        </div>
        <div className='hz-bar'/>
    </div>
    )}

export default HeaderBar
