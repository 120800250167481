import React, { Component, useState, useEffect} from "react";
import '../../../App.scss';
import './le.scss' 
import Content from '../content-block';
import HeaderBar from "../headerBar";
import FootBar from "../footerbar";

function LEA() {
    useEffect(() => {
        window.scrollTo(0,0);
     }, [])
    return   <>
    
        <HeaderBar Descr="PERSONAL WORK" Location="JUST FOR MYSELF" time ="2017 - 2018"/>
        <div className="container title-card le-title">
            <div className="section-block header-section">
             <div className="text-block row title-block ">
                    <div className="sffw-title-frame"> 
                        <h1>LAW ENFORCEMENT ACCOUNTABILITY & ACCREDITATION</h1>
                    </div>
                </div>
                <div className="text-block row title-block ">
                </div>
             </div>
        </div>
          <div className="container">
            <div className="section-block  text-section">
            <div className="text-block">
              <h2>ABOUT</h2>
              <h3>Since 2015, there are 900+ fatalities in the U.S. per year by police. According to the Washington Post's ongoing “Fatal Force” project</h3>
              <br></br>
              <h3>I would like these events to be less opaque. There are existing technologies that could make the truth accessible, and I wanted to explore a potential solution. 
              </h3>
              </div>
              <div className="text-block"> 
                <p> 
                    In 2017, there were 987 police-related deaths, followed by 998 in 2018, with this figure consistently hovering near 1000 since 2015. While not all these deaths result from excessive use of force, a record-keeping system is essential for comprehensive review. Body-worn camera programs offer a viable solution, with 66% of police and 93% of the public supporting their use, according to Pew Research. These programs foster trust, reduce tensions, and aid in the fair treatment of both law enforcement officers and citizens.                
                </p>
                <p> 
                    Rialto, California, was the pioneer in implementing body-worn cameras. A yearlong study in 2012 showed an 88% decline in officer complaints and a 60% drop in use-of-force incidents after their adoption. Similar results have been seen in departments nationwide, although some, like the DC Metropolitan Police Department, did not witness a decline in complaints despite adopting the technology in 2015-17. This suggests that cultural changes and oversight play a significant role in shaping outcomes within law enforcement agencies.
                </p>
                <p><b>Body worn camera programs...</b>
                    <ul>
                        <li>Greater assurance officers behave in an acceptable manner</li>
                        <li>Decrease excessive use of force </li>
                        <li>Collect evidence to convict the appropriate party </li>
                        <li>Improve public trust</li>
                    </ul>
                </p>
                <p><b> Project Goal </b></p>
                <p>Ensuring a record exists for all events involving police use-of-force</p>
                
                </div>
              </div>
            </div>
            <div className="container">
                <div className="section-block text-section">
                    <h2>RESEARCH: BODY WORN CAMERA PROGRAMS</h2>
                    <div className="text-block">
                        <p><b> Interviews - Law Enforcement </b></p>
                        <p>
                        <ul>
                            <li> Michael Boteiri - Police Chief Plymouth Police Department, Plymouth MA.</li>
                            <li> Stephen Cameron - Provost Marshall, Marine Corps Base Hawaii, USMC</li>
                            <li> Lt. Michael Rowling - Arlington Police Department, Arlington, VA.</li>
                        </ul>
                        </p>
                        <p><b>Obstacles / Concerns</b></p>
                        <p>
                            <ul>
                                <li> Law enforcement professionals are concerned about the potential for undue criticism from individuals lacking firsthand experience in the field.</li>
                                <li> Allowing the public to scrutinize an officer's actions may inadvertently lead to a decline in public trust, contrary to an intended goal.</li>
                                <li> Examining incidents devoid of proper context may fail to convey essential information and reinforce preexisting biases in viewers.</li>
                                <li> The heightened awareness of potential scrutiny can make officers overly cautious or distracted, potentially leading to incorrect responses in various situations.</li>
                            </ul>               
                        </p>
                        <p><b>Current Technology</b></p>
                        <p>
                        The utilization of Body-Worn Camera (BWC) devices necessitates officers to initiate recording manually. Departments implementing these programs typically provide training sessions conducted by representatives from the camera manufacturers. A key objective of this training is to instill the practice of incorporating the manual activation of the camera as a standard operating procedure for officers when approaching any event
                        </p>
                    </div>
                </div>
            </div>
        <div className="container">
            <div className="section-block text-section">
                <div className="prjct-image le-camera1"></div>
            </div>
        </div>
        <div className="container">
            <div className="section-block text-section">
            <div className="text-block"/>
                <div className="text-block">
                        <p><b> Industry Leaders </b></p>
                        <p>
                        <a href="https://www.axon.com/" target="_blank" title="Axon Company Site"> Axon,</a> formerly known as TASER International, stands as the preeminent industry leader in law enforcement technologies and equipment. Their pioneering creation of non-lethal electric weapons solidified their prominence. Axon's comprehensive offering, encompassing Body-Worn Cameras (BWCs) and data storage solutions, warrants careful consideration.         
                        </p>
                        <p>
                        Axon assumes a dominant position within the operational landscape by providing both the requisite devices and essential storage infrastructure. However, their current approach primarily centers on leveraging the network solely as a means for storing and transferring evidentiary material.         
                        </p>
                        <p>
                        Axon's business model for device and data storage services is structured similarly to subscription-based services like Comcast or Netflix. They initiated this project by offering an enticing free year to eligible police departments, anticipating that once the program is adopted and tested, it will become indispensable. CEO Rick Smith envisions an expected annual revenue of $1700 per officer from their service platform. Notably, the platform, known as Evidence.com, witnessed a significant volume, with 90 videos uploaded every minute as of the previous year.          
                        </p>
                        <p>
                        Axon excels in its core functions, facilitating effortless file transfer through an intuitive interface. It further enables effective manual meta-tagging for query purposes and adheres to legal specifications regarding records management systems.
                        </p>
                        
                    </div>
            </div>
        </div>
        <div className="container">
            <div className="section-block text-section">
            <div className="text-block">
            <h2>RESEARCH OUTCOMES: BWC PROGRAMS</h2>
            </div>
                <div className="text-block">
                        <p><b> Insights </b></p>
                        <p>
                            <ul>
                                <li> Reliance on manual activation can/does lead to loss of records</li>
                                <li> Integrating BWC training into established behavior protocol can disrupt correct procedure</li>
                                <li> Officer test groups who majority rejected BWC programs later supported it. 95% advocacy.</li>
                                <li> Eight out of ten agencies would either strongly recommend or recommend that other agencies adopt BWC programs.</li>
                                <li> For departments that adopt BWC programs see a large decline in public complaints against them.</li>
                            </ul>                        
                        </p>
                        <p><b> Goal Revised </b></p>
                        <p>
                        The solution ought to encompass an automated recording system for officers, affording them the confidence to approach situations in alignment with their training.
                        </p>
                        <p>
                        It is essential to regard the initial resistance of officers to such programs as a transient phase, with eventual majority support and adoption anticipated. Gaining the endorsement of key stakeholders such as Police Chiefs, municipal leadership, and department administration is pivotal in establishing a solid foundation for the program.
                        </p>
                        <p>
                        Based on discussions with both current and former agency leaders, a compelling factor in securing approval from decision-makers is the potential for cost reduction within departments.
                        </p>
                    </div>
            </div>
        </div>
        <div className="container">
        <div className="section-block text-section">
            <div className="text-block">
            <h2>RESEARCH: ACCREDITATION</h2>
            <h3>Benefits of being CALEA accredited - reduced risk and liability exposure & stronger defense against civil lawsuits </h3>
            <h3>Just 5% of the nations 18,000 law enforcement agencies are CALEA accredited</h3>
           
            </div>
                <div className="text-block">
                        <p> <a href="https://www.calea.org/" target="_blank" title="CALEA site"> The Commission on Accreditation of Law Enforcement Agencies (CALEA) </a> has been the leading authority in assessing law enforcement accountability since its establishment in 1979. CALEA's primary mission is to enhance public safety by upholding a comprehensive set of standards devised by public safety professionals, encompassing various contemporary public safety initiatives. This process involves administering an accreditation procedure and acknowledging professional excellence. CALEA-accredited agencies enjoy numerous advantages, including enhanced internal accountability, reduced exposure to risks and liabilities, improved defense against civil lawsuits, robust backing from government authorities, and increased community support. While some of these benefits relate to liability insurance payments, they collectively represent worthwhile objectives for any law enforcement agency.          
                        </p>
                        <p>
                        The accreditation process initiated by CALEA begins with a voluntary enrollment by the agency. The Chief Executive of the agency collaborates with a regional CALEA representative to select the specific criteria for assessment. Subsequently, the agency conducts a self-assessment, enhancing policies, facilities, and practices as deemed necessary. This phase of the process can span up to 24 months. Following the self-assessment, the regional representative returns, and together they conduct a formal evaluation of the agency's compliance with the chosen standards. Based on the results, the agency may either be granted or denied national accreditation recognition. Nonetheless, there are notable limitations in the current system, such as overreliance on external expertise, selective criteria assessment, and a lengthy timeline. The CALEA accreditation process, from enrollment to completion, can extend from one to three years, contingent on factors like the onsite assessment manager's schedule, chosen criteria, and the regional CALEA representative's availability.  
                        </p>
                    </div>
            </div>
            
        </div>
        <div className="section-block text-section">
            <div className="prjct-image le-police1"></div>
            <div className="prjct-image le-police2"></div>
            </div>
            <div className="container">
        <div className="section-block text-section">
            <div className="text-block">
            </div>
                <div className="text-block">
                        <p> <b>CALEA Process</b></p>
                        <p>
                            <ul>
                                <li> <span className="p-bold"> Enrollment: </span>The agency submits several forms - Enrollment Form, Program specific Accreditation Agreement, Publications Subscription and Access Agreement </li>
                                <li> <span className="p-bold"> Self-Assessment: </span>  Self-assessment refers to the internal, systematic analysis of an agency’s operations, management and practices to determine if it complies with applicable standards.</li>
                                <li> <span className="p-bold"> Assessment: </span>  The assessment includes a remote, web-based file review(s) and site-based assessment to confirm standards compliance. This includes public comment sessions, interviews with various individuals associated with the respective agency, process and outcomes-mapping, and community feedback. </li>
                                <li> <span className="p-bold"> Commission Review & Decision: </span>  The final credentialing decision is made by the Board of CALEA Commissioners. Each agency being presented for accreditation is assigned to an Agency Review Committee, which consists of CALEA Commissioners. The committee facilitates a public review hearing to discuss the findings of the assessment with agency representatives.</li>
                            </ul>                        
                        </p>                       
                    </div>
            </div>            
        </div>
        <div className="container">
            <div className="section-block text-section">
            <div className="text-block">
            <h2>RESEARCH OUTCOMES: ACCREDITATION</h2>
            </div>
                <div className="text-block">
                        <p><b> Insights </b></p>
                        <p>
                            <ul>
                                <li> Process is slow, 3+ years for completion</li>
                                <li> Process requires in-house assignee, consuming administrative human capital</li>
                                <li> Process can be costly</li>
                                <li> The Self-Assessment provides opportunity for cherry-picking data</li></ul>                        
                        </p>
                        <p><b> Goal Revised </b></p>
                        <p>
                        Law enforcement agency leadership are incentivized to pursue accreditation to bring down their liability insurance costs. But the process requires a large time/resource commitment and can present barriers of entry with initial fees. Simplifying this process for agencies could be an effective opening to get them on board with adopting accountability programs. 
                        </p>
                    </div>
            </div>
        </div>
        <div className="container">
            <div className="section-block text-section">
                <div className="prjct-image le-police3"></div>
            </div>
        </div>
        <div className="container ">
            <div className="section-block text-section">
            <div className="text-block">
            <h2>PROTOTYPE: BWC SYSTEM</h2>
            <p>
            The system reacts when an officer removes their firearm, or any other tool for use of force. This triggers the camera to begin recording via bluetooth personal area network (PAN). Current technology on the market consistently captures a buffer of the previous 30 seconds, ensuring events are captured fully.
            </p>
            <p><b>Solves for:</b>
                <ul>
                    <li> Any event in which the officer intends higher level use of force, the event will be recorded.</li>
                    <li> Removes manual initiation of recording. Officer can proceed with familiar protocol.</li>
                </ul>
            </p>
            </div>
                <div className="prjct-image proto-type-banner"></div>
             </div>
        </div>
        <div className="container">
        <div className="section-block general"> 
            <Content text="Arduino Prototype" text1= "Janky lil Prototype" text2="•	Setting up the board with a force sensing resistor as a stand-in for the notional holster" img={require('../../../images/bwc/JPEG/arduino.jpg')} alt="Arduino board prototype"/>  
            <Content text="FSR in Action" text1= "Converting Analog to Digital" text2="The arduino was connected to a Raspberry Pi via usb, this would  interpret the analog FSR data and run a method when it reached a lower limit. Since this setup was to measure the complete lack of force, the limit being '0' made things quite easy." img={require('../../../images/bwc/JPEG/screenshot.jpg')} alt="Pi screen interpreting FSR data"/>  
            <Content text="Camera Activated!" text1= "I was surprised" text2="Once the set lower limit was reached, the Pi would activate the connected webcam. This pic was the first capture of everything working." img={require('../../../images/bwc/JPEG/test.jpg')} alt="San Francisco Fleet Week"/>  
        </div>
        </div>
        <div className="container ">
            <div className="section-block text-section">
            <div className="text-block">
            <h2>PROTOTYPE: DATA HOUSING FOR ACCREDITATION</h2>
             <p><b>Individual Profiles</b>
            </p>
            <p>The storage of Body-Worn Camera (BWC) records is  linked to a unique identifier for each officer - such as their badge number. Within this account, a comprehensive repository is maintained, encompassing a wealth of valuable officer-related information. This includes but is not limited to their annual qualification schedule, performance scores on qualifications and training assessments, career history, detailing prior department and agency affiliations, as well as a record of any complaints lodged against them and their arrest history.</p>
            <p><b>Department Assessment</b></p>
            <p> 
            The department's assessment hinges on the cumulative officer profiles, with data collated based on each officer's ongoing compliance with various qualifications, detailed arrest records, interactions within the community, and adherence to established procedures during events. These profiles, along with departmental data, are securely stored on cloud servers, facilitating convenient access and expeditious delivery for CALEA assessment purposes.
            </p>
            <p><b>National Application</b></p>
            <p>
            The system is designed to enable comprehensive nationwide tracking of law enforcement data. Through rigorous analysis, it can discern trends and correlations within various environments, training programs, and policies. This innovative approach eliminates the need for resource-intensive processes that departments traditionally undergo to attain recognition of compliance with standards. Consequently, communities will no longer be served by unaccredited departments operating under non-standardized policies, ushering in a new era of improved law enforcement practices and enhanced accountability.
            </p>
            </div>
                <div className="prjct-image proto-type-banner1"></div>
               
             </div>
        </div>
        <div className="container ">
            <div className="section-block text-section">
            <div className="prjct-image proto-type-erd"></div>
            <p>Notional system's ERD. Tying together...
                <ul>
                <li>State geography and an individual officer's department. </li>
                <li>Arrests made by those officers and the charge codes on those arrests.</li>
                <li>Event video records from officer body worn cameras.</li>
                <li>State qualifications and tracking officer adherence.</li>
                <li>Complaints made against individual officers.</li>
                </ul>
                </p>
            </div>
        </div>
        <FootBar Descr="Get in Touch -->" Location="mike@mcameron.io"  />            </>
              }
              export default LEA;