
import { Routes, Route} from 'react-router-dom';
import Content from './content-block';
import { useNavigate, Link} from "react-router-dom";
import ProjectImage1 from "../../images/Compressed/sffw-lg.jpg";
import ProjectImage2 from "../../images/Compressed/leosa.jpg";
import ProjectImage3 from "../../images/Compressed/mcclep2.jpg";


import './PastWork.scss';
const PastWork = () => {
   let Path = ''
  let navigate = useNavigate(); 
  const routeChange = () =>{ 
    let path = Path
    navigate(path);}
    return(
       
        <div className="container project" >
        <div className="section-block general"> 
        <Link to={"./SFFW"}>
                <Content text="SFFW" text1= "San Francisco Fleet Week" text2="Role: Design Strategist, Graphic Designer, Photographer, Video Production" img={ProjectImage1} alt="San Francisco Fleet Week"/>  
        </Link>
       
        <Link to={"./MCCLEP"}>
                <Content text="MCCLEP" text1= "Marine Corps Civilian Law Enforcement Program" text2="Role: Design Strategist, Developer, Graphic Designer, Photographer, Video Production" img={ProjectImage3} alt="Marine Corps Civilian Law Enforcement Program"/> 
        </Link>
         </div>
    </div>
        
    )
  
}
export default PastWork;
