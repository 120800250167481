import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ReactMarkdown from 'react-markdown';
import './Chatbot.css';

const Chatbot = () => {
  const [messages, setMessages] = useState([
    { text: "Hi! I'm here to help you learn more about Mike's expertise and background. How can I assist you today?", sender: "bot" }
  ]);
  const [input, setInput] = useState('');
  const [threadId, setThreadId] = useState(null);
  const [isVisible, setIsVisible] = useState(false); // New state to control visibility

  useEffect(() => {
    if (isVisible && !threadId) {
      // Create a new thread when the chat is opened
      const createThread = async () => {
        try {
          console.log('Sending request to create thread...');
          const response = await axios.get('https://mcameron.io/nodeApp/thread');
          console.log('Response received:', response.data); // Log the full response
          if (response.data.threadId) {
            setThreadId(response.data.threadId);
            console.log('Thread created with ID:', response.data.threadId);
          } else {
            console.error('Thread ID not found in response:', response.data);
          }
        } catch (error) {
          console.error('Error creating thread:', error);
        }
      };

      if (!threadId) {
        createThread();
      }
    }
  }, [isVisible, threadId]);

  const handleSendMessage = async () => {
    if (input.trim() === '') return;

    const userMessage = input;
    setMessages(prevMessages => [...prevMessages, { text: userMessage, sender: 'user' }]);
    setInput('');
  
    if (!threadId) {
      console.error('Thread ID is not set.');
      return;
    }
  
    try {
      console.log(`Sending message to server: ${userMessage} with thread ID: ${threadId}`);
  
      const response = await axios.post('https://mcameron.io/nodeApp/message', {
        message: userMessage,
        threadId: threadId,
        userId: 'test-user-id' // Ensure to include a userId
      });
  
      console.log('Response from server:', response.data); // Log the response data
  
      // Navigate through the response structure to extract the "value"
      const botResponse = response.data.messages[0][0].text.value;
      console.log('Extracted bot response:', botResponse);
  
      setMessages(prevMessages => [...prevMessages, { text: botResponse, sender: 'bot' }]);
    } catch (error) {
      console.error('Error fetching response:', error.message);
      console.error('Error details:', error.response?.data || error);
  
      if (error.response) {
        console.error('Error status:', error.response.status);
        console.error('Error headers:', error.response.headers);
      }
  
      setMessages(prevMessages => [...prevMessages, "Sorry, there was an error processing your request."]);
    }
  };

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };
  console.log('Messages array:', messages);

  return (
    <div>
      <button className='button-chat-toggle' onClick={toggleVisibility}>
        <svg width="64px" height="64px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
          <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
          <g id="SVGRepo_iconCarrier">
            <path d="M3 5V20.7929C3 21.2383 3.53857 21.4614 3.85355 21.1464L7.70711 17.2929C7.89464 17.1054 8.149 17 8.41421 17H19C20.1046 17 21 16.1046 21 15V5C21 3.89543 20.1046 3 19 3H5C3.89543 3 3 3.89543 3 5Z" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
            <path d="M15 12C14.2005 12.6224 13.1502 13 12 13C10.8498 13 9.79952 12.6224 9 12" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
            <path d="M9 8.01953V8" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
            <path d="M15 8.01953V8" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
          </g>
        </svg>
        <p>
          {isVisible ? 'Close Chat' : "Mike's Assistant"}
        </p>
      </button>

      {isVisible && (
        <div className="chat-container">
          <div className="chat-header">Mike's Assistant</div>
          <div className="chat-message-container">
          {messages.map((message, index) => (
            <div
              key={index}
              className={message.sender === 'user' ? 'chat-message chat-message-right' : 'chat-message chat-message-left'}>
              
              <ReactMarkdown>{message.text ? message.text : 'Message is missing'}</ReactMarkdown> {/* Render Markdown content */}

            </div>
          ))}

          </div>
          <div className="chat-input">
          <input
              type="text"
              value={input}
              onChange={(e) => setInput(e.target.value)}
              placeholder="Type your message..."
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleSendMessage();
                }
              }}
            />
            <button onClick={handleSendMessage}>Send</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Chatbot;
