import React, { Component, useState, useEffect} from "react";
import '../../../App.scss';
import HeaderBar from "../headerBar";
import FootBar from "../footerbar";
import Content from '../content-block';
import './NASS.scss';
import data from "../../../images/NASS/datacows.jpg";
import pipes from "../../../images/NASS/pipes.jpg";
import handoff from "../../../images/NASS/handoff.jpg";

function NASS() {
  useEffect(() => {
    window.scrollTo(0,0);
 }, [])
  return   <>
      <HeaderBar Descr="DESIGN LEAD" Location="@ STEAMPUNK" time ="2020 - 2021"/>
      <div className="container title-card nass-title-card">
        <div className="section-block text-section"> 
          <div className="text-block row title-block">
              <h1> USDA: NATIONAL AGRICULTURE STATISTICS SERVICE </h1>
          </div>
            <div className="text-card m-right-side">
                <h3>
                The National Agriculture Statistics Service (NASS), a data-intensive division of the USDA, engaged our team to conduct a comprehensive 360° assessment of their data ecosystem. 
                Our goal was to identify areas for improvement and implement enhancements in the collection, management, and analysis of agricultural data.  
                </h3>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="section-block">
            <h2>ROLE</h2>
            <div className="text-block">
              <h3>Role Summary </h3>
              <p className="p-bold"> 
                <ul>
                <li>Conducted discovery into current data ecosystem and reporting processes</li>
                <li>Conducted stakeholder interviews across agency practices</li>
                <li>Extracted insights from qualitative data and secondary research</li>
                <li>Mapped business processes (current and future), align to repository dependencies</li>
                <li>Designed & developed Salesforce Service Cloud application front-end </li>
                </ul>
              </p>
              <p> 
              Initially, the project team consisted solely of designers. We explored NASS's existing data processes through stakeholder interviews and technical research to recommend improvements. We created artifacts to illustrate current procedures and propose enhancements, emphasizing user experience and system integration. In the project's second phase, I collaborated with developers on front-end features, managed the backlog, and led QA and user acceptance testing, leveraging my design insights and client knowledge. This experience was further enriched by learning from seasoned team members.            
              </p>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="section-block">
            <h2>ABOUT</h2>
            <div className="text-block">
              <p>
              The USDA's National Agricultural Statistics Service (NASS) conducts hundreds of surveys annually and prepares comprehensive reports on nearly every aspect of U.S. agriculture. 
              These reports cover production and supplies of food and fiber, prices paid and received by farmers, farm labor and wages, farm finances, chemical usage, and demographic changes 
              among U.S. producers, among other topics
              </p>
              <p>
              NASS is committed to providing timely, accurate, and useful statistics in service to U.S. agriculture. 
              <ul>
                <li><b>Report the facts</b> on American agriculture, facts needed by people working in and depending upon U.S. agriculture. </li>  
                <li><b>Provide objective and unbiased statistics</b> on a pre-announced schedule that is fair and impartial to all market participants. </li>  
                <li>Conduct the Census of Agriculture every five years,<b>providing the only source of consistent, comparable, and detailed agricultural data </b>for every county in America.</li>  
                <li><b>Serve the needs of our data users and customers</b> at a local level through our network of State field offices and our cooperative relationship with universities and State Departments of Agriculture. </li>  
                <li><b>Safeguard the privacy of farmers, ranchers, and other data providers</b>, with a guarantee that confidentiality and data security continue to be our top priorities.</li>  
              </ul> 
              -  <a href="https://www.nass.usda.gov/About_NASS/index.php" target="_blank" title="USDA NASS Site "> USDA NASS </a>
            
              </p>
          
            </div>
          </div>
        </div>
        <div className="container ">
          <div className="section-block ">
            <h2>CHALLENGE</h2>
            <div className="text-block">
            <h3>Summary</h3>
              <p className="p-bold"> 
                <ul>
                <li>Develop a single data collection platform for agricultural data</li>
                <li>Meet customer needs for improved online reporting</li>
                <li>Improve capability to cross-reference secondary sourced data with cases identified based on the NASS sampling frame</li>
                <li>Improve internal systems and processes to deliver customized data to a respondent</li>
                <li>Improve data sharing with internal USDA partners in accordance with the requirements of the Evidenced Based Policymaking Act</li>
                <li>Implement single source login for respondents </li>
                <li>Advise on new acquisition process </li>
                </ul>
              </p>
              <p>
              NASS fulfills its mission using a suite of tools and applications within a complex enterprise network. Numerous databases and repositories store essential data and support various stages of the survey lifecycle. Some applications are developed in-house, tailored specifically to NASS's needs, while others are third-party solutions modified to fit their use cases. Where modification wasn't feasible, NASS adapted its methods to align with these third-party tools. Over the years, NASS has acquired its current ecosystem through ongoing remediation of identified capability gaps, resulting in a diverse and complex family of systems.              </p>
              <p>
              For NASS to fulfill its mission, it requires a steady stream of survey responses from farmers and agribusinesses. These surveys must be generated, disseminated, and collected throughout the year. Responses are voluntary and can be submitted via mail, phone calls, online forms, or in-person interviews during field visits. Any obstacles or difficulties faced by respondents can reduce their participation. Therefore, this project needed to consider both the respondent experience and the technical processes for analyzing their submissions.
              </p>
            </div>
          </div>
        </div>
        <div className="container " >
              <div className="section-block general"> 
              <Content text="Data Management" text1= "NASS personnel frequently struggled to query their data repositories, often unable to locate information they knew existed. Additionally, the agency's data collection methods from surveys, combined with their automated intake system, produced non-normalized data entries, necessitating manual review and correction." img={data} alt="Data Management - Organized Cows"/>
              <Content text="Integration" text1= "NASS relies on several specialized systems throughout the survey lifecycle, each with its own standards and constraints. They needed a means to transfer data between these systems without manual intervention and conversions. " img={pipes} alt="Crop Irrigation"/>
              <Content text="Change Management" text1= "The agency tended to build out ad-hoc solutions for identified capability gaps. An aspect of this project was to pause this siloed solutioning and review how the current ecosystem may be contributing to challenges for the userbase. Our role included advising on a new acquisition process for future technical remediation and capability expansion." img={handoff} alt="Farm Workers"/>
                </div>
            </div>
       
        <div className="container">
          <div className="section-block">
            <h2>OUTCOME</h2>
            <div className="text-block">
              <p>
              By the conclusion of my involvement with the project, USDA NASS had an agency-wide survey calendar to better plan and coordinate survey efforts. An ongoing concern for the agency is respondent burden—if an agribusiness fell into several categories, multiple surveys could be sent to the business in succession, decreasing the likelihood of a response to each. Coordination on survey recipients allowed NASS to be more tactical in their outreach and leverage responses from one survey to fill the data needs of another.              </p>
              <p>
              An issue submission and tracking tool was developed for internal case resolution. A major challenge in the agency's previous state was internal coordination, particularly in issue resolution. Knowing who to contact regarding issues with NASS's numerous systems was difficult. Having a structured case submission system allowed the team to remediate and escalate issues effectively. 
              </p>
              <p>
              In preparing surveys, the survey design team manually searched and applied questions from previous iterations, duplicating formats of prompts and responses. There was no single source of truth for survey questions. At the time of my departure, the team was building a question repository to be merged with a new survey design tool for streamlined survey generation and reuse. This would feed into the agency’s three survey distribution methods—mail, online, and phone—resolving issues of variation between survey modalities and accuracy of responses.
              </p>
              <p>
              A larger shift behind the scenes was migrating data, where appropriate, to the Salesforce platform. This decreased the number of locations for survey data related to both preparation and analysis and aligned with the goal to reduce complexity. Some systems could not be migrated to the platform, necessitating integrations. However, critical external systems were connected, and by leveraging Salesforce’s OOTB reporting and dashboards, the NASS team could quickly gain insights into survey response success and submitted agricultural data.
              </p>
            </div>
          </div>
        </div>
        <FootBar Descr="Get in Touch -->" Location="mike@mcameron.io"  />    </> 
  }
  export default NASS;