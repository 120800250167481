import React, { Component, useState, useEffect} from "react";
import '../../../App.scss';
import HeaderBar from "../headerBar";
import './usaid.scss'
import FootBar from "../footerbar";
import Content from '../content-block';
import affinty from "../../../images/Compressed/usaidaffinity.jpg";
import people from "../../../images/Compressed/usaidpeople.jpg";
import process from "../../../images/Compressed/usaidprocess.jpg";

function USAID() {
  useEffect(() => {
    window.scrollTo(0,0);
 }, [])
  return   <>
      <HeaderBar Descr="DESIGN LEAD" Location="@ STEAMPUNK" time ="2022 - 2023"/>
      <div className="container title-card usaid-title">
      <div className="section-block text-section">
          <div className="text-block row title-block usaid-title-block">
            
                <h1> USAID: PARTNER RELATIONSHIP MANAGEMENT SYSTEM </h1>
           
            </div>
            <div className="text-card m-right-side">
                <h3>
                  Our team was tasked with building an enterprise CRM system to manage relationships with USAID's various external partners. The client team approached us with a vision - they wanted see a smarter agency as it 
                  collaborated with these critical partner organizations, they wanted insight on agency wide relationship dynamics, and quick reporting for tactical decision making. But this was all dependent on an engaged user base supplying the necessary information. 
                  I led the requirements gathering and human-centered design efforts.  
                </h3>
                  </div>
        </div>
        </div>
        <div className="container">
            <div className="section-block text-section">
            <div className="text-block">
              <h2>ROLE</h2>
            </div>
              <div className="text-block">
                    <p><b>Design Team Lead: </b>
                    Managed project's design team - UX designers, Change Management, and other Service Designers - prioritizing our efforts and keeping pace with the project roadmap. 
                    </p>
                    <p><b>Developed and Executed Research Plan: </b>
                    Created a comprehensive research plan and collaborated with USAID's leadership to identify key stakeholders.</p>
                    <p><b>Gathered Stakeholder Insights: </b>
                    Conducted interviews with users and leadership to gather qualitative data, forming the basis for system development.</p>
                    <p><b>Designing and Advocating for User-Centric Solutions: </b>
                    Translated requirements into system designs, formed an Advisory User Group for testing, and advocated for user preferences during QA.</p>
                  <p><b>Design Team</b></p>
                  <p><a href="https://caseysweeney.design/" target="_blank" title="Casey Sweeney's Portfolio Site">Casey Sweeney </a>- UX/UI Designer</p>
                  <p><a href="http://www.delanieostrow.com/" target="_blank" title="Delanie Ostrow's Portfolio Site">Delanie Ostrow </a>- Service Designer (Intern)</p>
                  <p></p>
              </div>
            </div>
          </div>
        <div className="container">
          <div className="section-block text-section">
          <div className="text-block">
            <h2>ABOUT</h2>
            </div>
            <div className="text-block">
              <p><i>
              Private enterprise is the single most powerful force for lifting lives, strengthening communities, and accelerating sustainable development.</i></p>
              <p>
              <i> 
              The world is changing, and how we approach development is responding to these changes. Today, private sources represent more than <b> 90 percent</b> of financial 
              flows into emerging markets. Inclusive growth can only be achieved when USAID works with the private sector—as a driver of capital markets— to spur greater
              development impact. Our approach to global development is enterprise-driven. The Agency is undergoing an intentional shift towards pursuing market-based approaches 
              and investment as a more sustainable way to empower people and communities worldwide.</i> <a href="https://www.usaid.gov/work-usaid/private-sector-engagement" target="_blank" title="USAID PSE Page"> - USAID Private Sector Engagement </a></p>
              </div>
          </div>
        </div>
        <div className="container">              
        <div className="section-block text-section">
        <div className="text-block">
            <h2>CHALLENGE</h2>
          </div>
            <div className="text-block">
              <h3>Summary</h3>
              <p className="p-bold">
                <ul>
                  <li>Global agency needing centralized knowledge base</li>
                  <li>Difficulty gaining insights on partner relationship dynamics</li>
                  <li>Global presence of the organization makes standardization a cultural challenge </li>
                </ul>
              </p>
              <p>
              In our efforts to enhance collaboration and efficiency, we explored ways to improve the man-agreement of partner relationships at a global level. Recognizing the importance of knowledge sharing and coordination, we considered the development of new strategies to facilitate communication across different teams and regions.
              </p>
              <p>
              We also investigated more effective methods for organizing and accessing contact information to ensure all team members had the resources they needed for successful engagement with external organizations.
              </p>              
              <p>
              Additionally, we addressed the need for a unified approach to understanding and managing partnerships, aiming to streamline processes while respecting the diverse needs and perspectives within the organization.</p>
              <p>
              Efforts were taken to evaluate solutions that supported the overall USAID mission. This included consideration of feedback from all levels of the organization to ensure any new systems or processes aligned with agency values and operational needs.</p>
              </div>
          </div>
          </div>

          <div className="container " >
              <div className="section-block general"> 
              <Content text="The People" text1= "USAID personnel work in varied environments, with staff at the Washington, D.C., headquarters and foreign service officers stationed in various countries focusing on local operations. Each location tends to operate with a degree of autonomy, developing its own approaches to partnership management based on local needs and conditions." img={people} alt="Affinity Mapping Results"/>
              <Content text="The Pain Points" text1= "In our efforts to improve organizational processes, we prioritized understanding the experiences and needs of those most affected by changes. By engaging with a broad cross-section of users from different parts of the organization, we gathered valuable insights into their daily challenges and requirements. This collaborative approach helped inform the development of solutions that not only addressed user needs but also aligned with the agency's broader goals, thereby facilitating smoother adoption." img={affinty} alt="Affinity Mapping Results"/>
              <Content text="The Process" text1= "The initiative involved creating a new tool to help standardize and improve partner engagement practices across the organization. Recognizing the diversity of existing practices among different teams and international offices, the new system was designed to enhance coordination and efficiency without significantly disrupting established workflows. This approach aimed to support a more unified and effective operational framework." img={process} alt="Affinity Mapping Results"/>
                </div>
            </div>
        
        
        <div className="container">
          <div className="section-block text-section">
          <div className="text-block">
            <h2>OUTCOME</h2>
            </div>
            <div className="text-block">
              <p>
              The most important achievement of our initiative was ensuring that all agency personnel have access to consistent and accurate information regarding their partnerships,
               enhancing global coordination and operational efficiency. The initiative successfully addressed previous challenges in partner engagement, 
               allowing for more unified and strategic agency-wide operations.
              </p>
              <p> The system improved the organization and accessibility of contact information and project data, making it easier for teams to collaborate 
                and share knowledge. This has led to a more integrated approach to managing relationships and projects, significantly improving internal coordination.
              </p>
              <p>Additionally, we implemented improvements to data management across the agency, which improved the efficiency and effectiveness of the system's core processes. 
                This included integrating existing tools and platforms to streamline information retrieval and document management.
              </p>
              <p>Furthermore, we developed tools to aid in the analysis and planning of engagements, enabling better strategic decisions to leaders and a more informed approach to partnerships and projects.
              </p>
              <p>Overall, these improvements have greatly enhanced operational capabilities, allowing the agency to work more effectively and respond more dynamically to the needs of the communities they serve. 
                This has also enabled them to better prioritize and manage partnerships, leading to more effective and impactful outcomes.
              </p>
            </div>
          </div>
        </div>
        <FootBar Descr="Get in Touch -->" Location="mike@mcameron.io"  />

    </> 
       
  }
  export default USAID;