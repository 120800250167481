import './ServDes.scss';
import ModalHandler from './modal';
import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import ProjectImage3 from "../../images/Compressed/mcclep2.jpg";
import { useNavigate } from "react-router-dom"
import Navigate from '../../components/functions/Route'

const ServDesWork=() => {
    let usda = "https://www.nass.usda.gov/";
    let dcma = "https://www.dcma.mil/";
    let usmc =  "https://www.marines.mil/News/Tag/23165/military-police/";
    let usaid = "https://www.usaid.gov/";
    return(
        
        <> 
 
  <div className="section-block desengagement deseng-block-1 " >
       <div className="content-block servdes-block servdes-block-title ">
        <div className='content-container content-des-eng'> 
            <h1>MY APPROACH TO DESIGN ENGAGEMENTS  </h1>

           
        </div>
       </div>
        <div className="content-block servdes-block servdes-block-text"> 
        <div className='content-container content-des-eng des-eng-bottom-text-container'>  

                <div className="content-text bottom"> 
                <h3>I help make this...</h3>
            </div>
        </div>
        </div>
        <div className='content-block servdes-block servdes-block-text'>
        <div className='content-container content-des-eng des-eng-bottom-text-container'> 
       
            <div className="content-text bottom"> 
                <h3>...into this</h3>
            </div>
            </div>
       </div>
    </div>
    <div className="section-block desengagement deseng-block-2" >
        <div className='text-card fulltext-card'>
          
            <p>
            As a designer, I prioritize listening to customers, employees, and stakeholders in order to gain a 
            comprehensive understanding of the interactions they have with an organization and its services. 
            I use design activities to analyze complex scenarios and collaboratively develop innovative solutions 
            that meet the needs and desires of all parties involved. By taking a holistic approach, I aim to create solutions that benefit everyone.
            <br>
            </br>
            <br>
            </br>
            I have worked with great teams from...
            </p>
                <a className='content-block logo'  alt="USDA Logo" title="USDA Food and Nutrition Services" href="" onClick={() => {  window.open(usda, "_blank")}}>
                    <img className="content-thumb"   src={require('../../images/Compressed/usda_logo.png') } alt="United States Department of Agriculture" />
                </a>
                <a className='content-block logo' title="Defense Contract Management Agency" href="" onClick={() => {  window.open(dcma, "_blank")}}>
                    <img className="content-thumb " src={require('../../images/Compressed/dcma.png')} alt="Defense Contract Management Agency"/>
                </a>
                <a className='content-block logo' alt="USMC Logo"  tile="USMC Military Police" href="" onClick={() => {  window.open(usmc, "_blank")}}>
                    <img className="content-thumb " src={require('../../images/Compressed/usmc.png')} alt="U.S. Marine Corps"/>
                </a>
                <a className='content-block logo' title="USAID Logo" href="" onClick={() => {  window.open(usaid, "_blank")}}>
                    <img className="content-thumb " src={require('../../images/Compressed/usaid.png')} alt="United States Agency for International Development"/>
                </a>
            
        </div>
    </div>
    <div className="container">
          <div className="section-block">
          <div className="text-block"> 
            <h3>A BLEND OF HUMAN-CENTERED AND BUSINESS-ORIENTED APPROACHES</h3>
        </div>
            <div className="text-block"> 
                <p> 
                I combine business acumen and design expertise to lead agile teams in uncovering requirements and delivering outcomes for key business initiatives. My guiding principle is always to prioritize people in our work. With several years of experience, I've refined my ability to collaborate effectively with teams of all sizes and have led numerous design efforts.
                </p>
                <p>
                My diverse background allows me to view challenges from both organizational and individual perspectives, a valuable asset in our work. I frequently bring in team members such as solution architects and business analysts to collaborate. When the broader team understands those affected by our work, we can create solutions that generate more value.
                </p>
                <p>
                Below is a narrative breakdown of how I approach design engagements. This approach is flexible and continually evolving as I learn better ways to organize my work.
                </p>
              </div>
            </div>
          </div>   

</>
    )
}
export default ServDesWork;