import React, { Component, useState, useEffect} from "react";
import '../../../App.scss';
import './Working.scss';
import HeaderBar from "../headerBar";
import BGBanner from '../../../images/Des.Engagement1.png';
import { Chrono } from "react-chrono";
import ServDesWork from '../ServDes';
import test from "../../../images/working/view.jpg"  
function Working() {
  const items = [{
    title: "Pre-Kickoff",
    cardTitle: "Level Set",
    cardSubtitle:"Collaborative discussions with the client or product owners to understand the project's big picture (100,000 ft view), intermediate goals (10,000 ft view), and immediate needs (1,000 ft view) to ensure alignment before starting.",
    cardDetailedText: "Early conversations focus on understanding the external team's perception of the issue, where they currently stand, and where they want to go. This is where we begin to map out their user and stakeholder ecosystem and identify roles for potential interviews.",
    media: {
      type: "IMAGE",
      source: { url:require('../../../images/working/view.jpg') }      
    }
  },
  {
    title: "Kickoff",
    cardTitle: "Prep Work",
    cardSubtitle:" Understanding team dynamics. Who am I working with on the client side? Who are the key players on the development team?",
    cardDetailedText: "This phase includes forming and norming with the internal team—discussing how we will work together, communicate, and define roles. With the client, I introduce our approach, present the research plan, and familiarize them with our design methodologies. I also work to understand their work breakdown structure.",
    media: {
      type: "IMAGE",
      source: { url:require('../../../images/working/hello.jpg') } 
    }
  },
  {
    title: "Post Kickoff",
    cardTitle: "Research",
    cardSubtitle:"Identifying gaps in knowledge: What do we think we know? What do we need to know?",
    cardDetailedText: "While the double diamond approach may feel outdated, starting broad is still valuable. After kickoff, I gather as many resources as possible to frame the problem space. For modernization projects, I look for user manuals, data ecosystem maps, and IT POCs with relevant expertise. For new projects, I research analogous efforts in other industries to see how similar processes have been managed. ",
    media: {
      type: "IMAGE",
      source: { url:require('../../../images/working/question.jpg') } 
    }
  },
  {
    title: "Discovery",
    cardTitle: "Stakeholder Interviews",
    cardSubtitle:"Gaining insights from those directly affected.",
    cardDetailedText: "It's natural to have assumptions at the beginning of any project, but it's critical to rely on those immersed in the space to understand the best way to effect change. Interviews are central to my discovery process. I keep them intimate—usually just myself and a note-taker—and unstructured, allowing the conversation to evolve naturally while still achieving set goals. ",
    media: {
      type: "IMAGE",
      source: { url:require('../../../images/working/chair.jpg') } 
    }
  },
  {
    title: "Co-Creation",
    cardTitle: "Collaboration & Activities",
    cardSubtitle:"Collaborating to clarify solutions while fostering a positive environment.",
    cardDetailedText: "In my experience, stakeholder groups often operate in silos, leading to misaligned perspectives. Bringing together diverse roles for co-creation sessions or workshops not only progresses the project but also helps stakeholders understand each other better. For example, management might gain insight into their subordinates' workloads, leading to more empathetic and effective solutions. I strive to keep these events light yet productive, often holding them offsite to avoid reinforcing hierarchical habits.",
    media: {
      type: "IMAGE",
      source: { url:require('../../../images/working/collab.jpg') } 
    }
  },
  {
    title: "Synthesis",
    cardTitle: "Integrating Insights",
    cardSubtitle:"Synthesizing findings: What did we learn? Where are we now?",
    cardDetailedText: "I treat each project as a puzzle with an undetermined number of pieces. I synthesize insights from interviews, collaborative sessions, stakeholder shadowing, and workshops, combining them with our original challenge to determine how everything fits together. My role often involves defining the overlap between the business's vision and the user's or employee's needs.",
    media: {
      type: "IMAGE",
      source: { url:require('../../../images/working/puzzle.jpg') } 
    }
  },
  {
    title: "Prototyping & Testing",
    cardTitle: "Validating Our Approach",
    cardSubtitle:"Assessing accuracy: What did we get right? Where did we miss the mark?",
    cardDetailedText: "The goal is always to reach this phase sooner rather than later. In design efforts, it’s cheaper and easier to test early ideas through mockups, user journeys, or process flows. My focus is on finding a balance between low-cost and accurate representation. I often use tools like Lucidchart or Mural, which are invaluable for virtual collaboration. After confirming the desired workflows, I guide UX/UI designers in their implementation, then revalidate the approach with the client.",
    media: {
      type: "IMAGE",
      source: { url:require('../../../images/working/gap.jpg') } 
    }
  },
  {
    title: "Iterating ",
    cardTitle: "Refining & Improving",
    cardSubtitle:"How do we fix what’s wrong and build on what’s right?",
    cardDetailedText: "Most of my work is within agile projects, which, in government settings, can be somewhat challenging. We often revisit previous steps—discovery, synthesis, prototyping, and testing—narrowing our understanding of success with each cycle. Confirmed requirements are set aside while gaps and misunderstandings are further explored.",
    media: {
      type: "IMAGE",
      source: { url:require('../../../images/working/postits.jpg') } 
    }
  },
  {
    title: "Release ",
    cardTitle: "Managing Change",
    cardSubtitle:"How do we launch this product successfully?",
    cardDetailedText: "This isn’t a question to leave until the end but one to clarify throughout the project. I work with PMs and change management teams to identify the smoothest transition for users and stakeholders. Every new tool brings some level of disruption; our goal is to ensure the most comfortable experience for those impacted by the change.",
    media: {
      type: "IMAGE",
      source: { url:require('../../../images/working/change.jpg') } 
    }
  },

];

useEffect(() => {
  window.scrollTo(0,0);
}, [])
  return   <>
      <HeaderBar Descr="DESIGN PROCESS" Location="ALWAYS EVOLVING" time ="1988 -  PRESENT"/>
        <ServDesWork/>
        <div className="container working-container">
          
          <div className="section-block timeline-block">
            <Chrono items={items} 
            enableOutline 
            mode="VERTICAL_ALTERNATING"
            hideControls={true}
            allowDynamicUpdate={true}
            cardHeight ={600}
            cardWidth={700}
            theme={{
              primary: '#212121',
              secondary: '',
              cardBgColor: '#fbfbfb',
              titleColor: '#606060',
              titleColorActive: 'black',
            }}
            classNames={{
              card: 'my-card',
              cardMedia: 'my-card-media',
              cardSubTitle: 'my-card-subtitle',
              cardText: 'my-card-text',
              cardTitle: 'my-card-title',
              controls: 'my-controls',
              title: 'my-title',
            }}
          />
          </div>
        </div>
        <HeaderBar Descr="PERSONAL INFO" Location="TO GET IN TOUCH" time =" - "/>
      <div className='container project-sm'> 
        <div className="header card"> 
          <div className="section-block text-section"> 
              <h1 >MICHAEL CAMERON <br/>   
              <a id="openModal">  <h1 className="hover-underline-animation">  mike@mcameron.io  </h1></a> 
              </h1>
            </div> 
          </div>
      </div>
        
    </> 
    
  }
  export default Working;