import React, { Component, useState, useEffect} from "react";
import '../../../App.scss';

import Section from "../../Layout/Section";
import Content from '../content-block-light';

import HeaderBar from "../headerBar";
import FootBar from "../footerbar";
import ContentBar from '../content-bar';
import ibidsboard from "../../../images/Compressed/ibidsboard.png";
import sflogo from "../../../images/Compressed/sf2pw.png";
import dataMap from "../../../images/Compressed/ibidsmap.jpg";
import mural from "../../../images/Compressed/dcmamural.jpg";
import './DCMA.scss';


function DCMA() {
  useEffect(() => {
    window.scrollTo(0,0);
 }, [])
  return   <>
      <HeaderBar Descr="DESIGN LEAD" Location="@ STEAMPUNK" time ="2021 - 2022"/>
      <div className="container title-card">
        <div className="section-block section-text title-text"> 
      <div className="text-block row title-block dcma-title">
            <div className="dcma-title-frame"> 
              <h1> DEFENSE CONTRACT MANAGEMENT AGENCY </h1>
            </div>
        </div>
        <div className="bgflow"> 
          <div className="text-card m-right-side  ">
          
            <h3>
              Our team was brought on to modernize multiple systems for DCMA, each supporting unique workstreams of the agency.
              My role was to lead all design efforts. 
            </h3>
              </div>
              </div>
        </div>
       
        </div>
        <div className="container">
            <div className="section-block">
            <div className="text-block">
              <h2>ROLE</h2>
            </div>
              <div className="text-block">
              <h3>
                Role Summary:</h3>
                <p className="p-bold">
                  <ul>
                    <li> Project Management </li>
                    <li> Generating Research Plan and Project Roadmap</li>
                    <li> Stakeholder Engagement and User Research </li>
                    <li> Technical Discovery </li>
                    <li> Requirements Gathering and Refinement </li>
                    <li> User Story Generation and Backlog Management </li>
                    <li> Development</li>
                    <li> Manage User Acceptance Testing </li>
                    <li> Receive UAT Feedback and Address Defects</li>
                    <li> Iterate Features </li>
                    <li> Prep for Release </li>
                  </ul>
                </p> 
                <p>
                Early on, the team was quite small. Myself, our Program Manager, and one solution architect kicked off the project as we actively sought effective Microsoft developers. 
                I was <b>leading any/all design efforts</b>, but also acting as a <b>project manager and developer </b>for a single application's modernization. </p> 
                <p>
                Being a design lead required me to set the discovery timeline and criteria for all engagements. This discovery timeline would vary depending on the reported level of 
                complexity and the size of the user base of a specific application. An example of a typical design engagement with DCMA can be seen below. 
                </p>
              
                <p>
                As a stand-in PM/Dev, I was the primary point of contact for the client team. Running standups, refining system requirements, leading technical discovery, and managing the product backlog(s). 
                </p>  
              
                <p><b>Design Team</b></p>  
                <p> <a href="https://caseysweeney.design/" target="_blank" title="Casey Sweeney's Portfolio Site">  Casey Sweeney </a>- UX/UI Designer </p>       
                <p> <a href="http://nickbrowndesigns.com/" target="_blank" title="Nick Brown's Portfolio Site">  Nicholas Brown </a>- UX/UI Designer </p>
                </div>
              </div>
          </div> 
        <div className="container">
          <div className="section-block">
          <div className="text-block">
            <h2>ABOUT</h2>
            </div>
            <div className="text-block"> 
              <p>
                <i> 
                  The Defense Contract Management Agency provides contract administration services for the Department 
                  of Defense, other federal organizations and international partners, and is an essential part of the 
                  acquisition process from pre-award to sustainment.

                  Every business day, DCMA receives nearly 1,000 new contracts and authorizes more than $650 million
                  in payments to contractors. Most importantly, every day the DCMA team delivers more than a million 
                  and a half items - from fighter jets to fasteners - to our warfighters.
                </i> 
               
                <a href="https://www.dcma.mil/" target="_blank" title="DCMA Homepage"> - DCMA </a>
              </p>
              </div>
            </div>
          </div>
        <div className="container">
          <div className="section-block">
          <div className="text-block project-text-block">
            <h2>CHALLENGE</h2>
            </div>
             <div className="text-block">
              <h3>Summary</h3>
              <p className="p-bold">
                <ul>
                  <li> Modernizing several antiquated applications </li>
                  <li> Application Conversion from Salesforce to MS PowerApps</li>
                  <li> Bring in publicly submitted data securely into DCMA repositories</li>
                  <li> Leverage data for rapid, key decision making via analytics</li>
                </ul>
              </p>
            <p>This project involved updating several outdated applications and improving data integration and analysis capabilities. The initiative aimed at enhancing decision-making processes and service quality through better data utilization.  
            </p>
            <p>The project was part of a broader Modernization and Analytics Initiative, focusing on updating and optimizing the agency's existing toolset for improved performance and integration. The goal was to enable the agency to make more informed decisions by leveraging their extensive data resources more effectively.
            </p>
            <p>The initiative began with efforts to update and develop new tools to meet current needs. As the project progressed, it became apparent that aligning with existing technological frameworks was crucial for seamless integration and efficiency, leading to adjustments in the development approach.
            </p>
            <p>
            Our team contributed to this strategic shift, ensuring that the project remained aligned with the agency's operational needs and technological environment.
            </p>
            </div>
            </div>
          </div>
         
          <ContentBar img={ibidsboard} alt="Design Engagement Timeline"/>     
    

          <div className="container">
          <div className="section-block">
          <div className="text-block">
            <h2>OUTCOME</h2>
            </div>
            <div className="text-block">
              <p>
              By the end of my involvement in the project, our team had successfully advanced several systems to the final stages of user acceptance testing, with one system being fully deployed. 
              The modernization efforts involved utilizing a contemporary platform to enhance application functionality and improve strategic data analysis. 
              The integration of user feedback mechanisms in some of the applications also marked a significant milestone, achieved within the framework of stringent security requirements.
                </p> 
              <p><b>Some Accomplishments</b>
                <ul>
                  <li>Collected valuable feedback from numerous users across different applications, enhancing the understanding of user needs and experiences.</li>
                  <li>Successfully harmonized the diverse needs of various stakeholders, ensuring that the solutions delivered met both agency objectives and user expectations.</li>
                  <li>Enhanced the ability of both management and team members to monitor and respond to essential tasks and project developments effectively.</li>
                  <li>Safely expanded communication avenues, allowing for a more inclusive approach to gathering insights on key issues from both internal staff and the wider public.</li>
                </ul>
              </p>

              
              </div>
            </div>
            </div>
            <div className="container project-sm project-page" >
              <div className="section-block general"> 
                <Content text="Userbase Investment" text1= "In each of our workstreams we focused on the people most effected by the modernization. Interviewing 50+ participants across the applications userbase, we drew from their challenges and found solutions that fit both their needs and the agency's overall mission. Driving adoption." img={mural } alt="Affinity Mapping Results"/>
                <Content text="System Migration" text1= " The team successfully transitioned the initial application from Salesforce to MS Power Platform. The largest hurdle in this effort was bringing the original experiences seen with Salesforce's out-of-the-box features to thePower Platform - these essentially needed to be built from the ground up. " img={sflogo} alt="System Migration Success"/>
                <Content text="PowerBi" text1= " A huge motivator for the agency was to extract strategic insights from their vast data repositories. We delivered this through PowerBI by establishing real-time standard reports as well as providing accessible custom report generation and data modelling to highlighting dependencies and relationships previously hidden from the agency. " img={dataMap} alt="Data Modelling and Insights"/>
              </div>
            </div> 
            <FootBar Descr="Get in Touch -->" Location="mike@mcameron.io"  />   
      </> 
    
  }
  export default DCMA;
