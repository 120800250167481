import React from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
  Link,
  Navigate 
} from "react-router-dom";
import Home from './components/Layout/Pages/Home';
import DCMA from './components/Layout/Pages/DCMA';
import NASS from './components/Layout/Pages/NASS';
import FNS from './components/Layout/Pages/FNS';
import USAID from './components/Layout/Pages/USAID';
import WORKING from './components/Layout/Pages/Working';
import SFFW from './components/Layout/Pages/SFFW';
import MCCLEP from './components/Layout/Pages/MCCLEP';
import AS from './components/Layout/Pages/activeshooter';
import LE from './components/Layout/Pages/LE';

const App = () => {
  return (
   <BrowserRouter> 
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path= "/DCMA" element={<DCMA />} />
      <Route path= "/FNS" element={<FNS />} />
      <Route path= "/NASS" element={<NASS />} />
      <Route path= "/USAID" element={<USAID />} />
      <Route path= "/WORKING" element={<WORKING />} />
      <Route path= "/SFFW" element={<SFFW />} />
      <Route path= "/MCCLEP" element={<MCCLEP />} />
      <Route path= "/AS" element={<AS />} />
      <Route path= "/LE" element={<LE />} />
    </Routes>
    </BrowserRouter>
  );
}

export default App;